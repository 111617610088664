import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import * as imgs from './assets';
import { imgBackground, imgLogo, imgMiddle } from "./assets";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

const styles: any = (theme: any) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: "rgba(255, 255, 255, 1)",
    backgroundImage: `url(${imgBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    fontFamily: '"HelveticaNeue-Medium", sans-serif',
  },
  sideOne: {
    // background: 'rgba(255, 255, 255, 1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: 30,
    '& .footer1': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: 18,
      '& p': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: 'rgba(35, 35, 35, 1)',
        fontFamily: '"HelveticaNeue", sans-serif',
        fontSize: 18,
        "@media (max-width:768px)": {
          marginRight:10
        },
      }
    }
  },
  contentOne: {
    '& .nav': {
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      // marginRight: 112,
      padding: 20,
      '& .textlabel_newtoIncluzonText': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        fontFamily: '"HelveticaNeue-Medium", sans-serif',
        '& Button': {
          backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRadius: 4,
          boxShadow: 'none',
          width: 100,
          color: 'rgba(3, 3, 3, 1)',
          textAlign: 'center',
          fontFamily: '"HelveticaNeue-Medium", sans-serif',
        }
      }
    },
    '& .loginBox': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '70%',
      '& loginBoxContent': {
        width: '250px',
        height: '250px',
        background: 'red'
      }
    },
  },
  logoRoot: {
    '& .textlabel_welcomeText': {
      fontSize: '24px',
      fontFamily: '"HelveticaNeue-Medium", sans-serif',
    },
    '& image_LogoImage': {
      position: 'relative'
    },
    '& textlabel_InventoreText': {
      fontSize: '18px',
      fontFamily: '"HelveticaNeue-Medium", sans-serif',
      position: 'relative',
    }

  },
  paperRoot: {
    boxShadow: '0 3px 13px 0 rgb(0 0 0 / 20%)',
    transition: ' 0.3s',
  },
  cardRoot: {
    minWidth: 400,
    boxShadow: 'none',
    padding: '0px 90px',
    borderRadius: 36,
    "@media (max-width:1024px)": {
			padding: '0px 30px',
		},
		"@media (max-width:768px)": {
			padding: '0px 0px',
		},
    '& .MuiCardActions-root': {
      display: 'flex',
      padding: 16,
      alignItems: 'self-end',
      justifyContent: 'end',

    },
  },
  cardContent: {
    '& .title': {
      marginBottom: 15
    },
    '& .textlabel_loginText': {
      position: 'relative',
      backgroundColor: 'transparent',
      fontStyle: 'normal',
      fontWeight: 'bold',
      color: 'rgba(33, 33, 33, 1)',
      fontFamily: '"HelveticaNeue-CondensedBold", sans-serif',
      fontSize: 32
    },
    '& .inputBox': {
      '& input': {
        height: 25,
        width: 450,
        "@media (max-width:768px)": {
          width: 350,
        },
        padding: 6,
        marginBottom: 10,
        borderRadius: 3,
        border: '1px solid rgba(89, 89, 89, 0.6)',
        color: 'rgba(89, 89, 89, 0.6)',
      },
      '& textarea': {
        width: 450,
        "@media (max-width:768px)": {
          width: 350,
        },
        padding: 6,
        marginBottom: 10,
        borderRadius: 3,
        border: '1px solid rgba(89, 89, 89, 0.6)',
        color: 'rgba(89, 89, 89, 0.6)',
      }
    }

  },
  sideImg:{
    width:370,
    "@media (max-width:768px)": {
			width: 160,
		},
  },
  image_LoginImage: {
    width: '100%',
    margin: 20
  },
  button_submitButton: {
    backgroundColor: 'rgba(1, 129, 123, 1)',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: 6,
    color: 'rgba(255, 255, 255, 1)',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontFamily: '"HelveticaNeue-Medium", sans-serif',
    fontSize: 18,
    textAlign: 'center',
    boxShadow: 'none',
    width: 144
  },
  textlabel: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: 'rgba(35, 35, 35, 1)',
    fontFamily: '"HelveticaNeue", sans-serif',
    fontSize: 18,
    "@media (max-width:768px)": {
      fontSize: 15,
    },
  },
  selectInput:{
    width:'100%',
    border: '1px solid rgba(89, 89, 89, 0.6)',
    color: 'rgba(89, 89, 89, 0.6)',
    padding: 6,
    borderRadius: 3,
    height: 35,
    marginTop: 15,
  }

});
class EmailAccountRegistration extends EmailAccountRegistrationController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.root}
        // style={{backgroundImage: `url(${imgBackground})`}}
        >
          <Grid container >
            <Grid item xs={4} className={classes.sideOne}>
              <div className={classes.logoRoot}>
                <div >
                  <Typography gutterBottom className="textlabel_welcomeText" >
                    Welcome to
                  </Typography>
                </div>

                <img src={imgLogo} className="image_LogoImage" />

                <Typography variant="subtitle2" gutterBottom className="textlabel_InventoreText" >
                  Inventore veritas et quasi architetto beata Vitae dicta sunt
                  explicabo. Nemo Enim ipsam voluptatem quia voluptas sit
                  Aspernatur aut odio aut fugit, sed quia Consequuntur
                  inventore veritatis et quasi</Typography>
              </div>
              <div className={classes.sideImg}>
                <img src={imgMiddle} className={classes.image_LoginImage} />
              </div>
              <div className="footer1">
                <p>Contact us</p>
                <p>Terms</p>
                <p>Privacy</p>
              </div>
            </Grid>
            <Grid item xs={8} className={classes.contentOne}>
              <div className="nav">
                <p className="textlabel_newtoIncluzonText">
                  Already a member?
                  <span style={{ marginLeft: 9 }}>
                    <Button variant="contained"
                      onClick={() => window.location.href = "/"}
                    >
                      LOGIN
                    </Button>
                  </span>
                </p>
              </div>
              <form >
                <div className="loginBox">
                  <Paper elevation={0} className={classes.paperRoot} >
                    <Card className={classes.cardRoot}>
                      <CardContent>
                        <div className={classes.cardContent}>
                          <div className="title" >
                            <Typography
                              className="textlabel_loginText"
                            >
                              SIGNUP
                            </Typography>
                          </div>
                          <div className="inputBox">

                            <input
                              type="text"
                              id="fullName"
                              name="fullName"
                              placeholder="Full Name"
                              onChange={(e) => this.txtInput(e)}
                            />
                            {
                            this.state.fullNameError ? (
                              <p>
                                EmailAccountRegistrationController.Full Name
                              </p>
                            ) : null
                          }
                            <br />
                            <input
                              type="text"
                              id="companyName"
                              name="companyName"
                              placeholder="Company Name"
                              onChange={(e) => this.txtInput(e)}
                            />
                            {
                            this.state.companyNameError ? (
                              <p>
                                EmailAccountRegistrationController.Company Name
                              </p>
                            ) : null
                          }
                            <br />
                            <input
                              type="text"
                              id="phoneNumber"
                              name="phone"
                              placeholder="Phone Number"
                              onChange={(e) => this.txtInput(e)}
                            />
                            {
                              this.state.phoneError? (
                                <p>
                                EmailAccountRegistrationController.Phone number is required
                              </p>
                            ) : null
                            }
                            
                            <br />
                            <input
                              type="email"
                              id="companyEmail"
                              name="email"
                              placeholder="Company Email"
                              onChange={(e) => this.txtInput(e)}
                            />
                            {
                            this.state.emailError ? (
                              <p>
                                EmailAccountRegistrationController.Company Email
                              </p>
                            ) : null
                          }
                            {/* {
                            this.state.emailStatusError ? (
                              <p>
                                EmailAccountRegistrationController.enter valid Email
                              </p>
                            ) : null
                          } */}
                            <br />
                            <input
                              type="text"
                              id="password"
                              name="password"
                              placeholder="password"
                              onChange={(e) => this.txtInput(e)}
                            />
                            {
                            this.state.passwordStatusError ? (
                              <p>
                                EmailAccountRegistrationController.Must be greater then 6
                              </p>
                            ) : null
                          }
                            <br />
                            <input
                              type="text"
                              id="conformpassword"
                              name="conformPassword"
                              placeholder="Conform Password"
                              onChange={(e) => this.txtInput(e)}
                            />
                            {
                            this.state.reTypePasswordError ? (
                              <p>
                                EmailAccountRegistrationController.confirm is Password Required
                              </p>
                            ) : this.state.reTypePasswordStatusError ? (
                              <p>
                                EmailAccountRegistrationController.confirmPaswordNotmatch
                              </p>
                            ) : null
                          }
                            <br />
                            <textarea
                              rows={4}
                              cols={50}
                              id="anyEnquiry"
                              name="anyEnquiry"
                              placeholder="Any brief about enquiry"
                              onChange={(e) => this.txtInput(e)}
                            />
                            <br />
                            <Grid container spacing={3}>
                              <Grid item xs={4}>
                                <label htmlFor="companySize" 
                                className={classes.textlabel} >Company Size</label>
                                <br />
                                <select 
                                name="companySize" 
                                id="companySize" 
                                placeholder="Company Size"
                                className={classes.selectInput}
                                onChange={(e) => this.txtInput(e)}
                                >
                                  <option value="10-">10-</option>
                                  <option value="10+">10+</option>
                                  <option value="100+">100+</option>
                                  <option value="1000+">1000+</option>
                                </select>
                              </Grid>
                              <Grid item xs={8}>
                                <label htmlFor="hearAbout" 
                                className={classes.textlabel} >How did you hear about us?</label>
                                <br />
                                <select 
                                name="hearAbout" 
                                id="hearAbout" 
                                placeholder="Social Media"
                                className={classes.selectInput}
                                onChange={(e) => this.txtInput(e)}
                                >
                                  <option value="Socialmedia">Social Media</option>
                                  <option value="linkedin">LinkedIn</option>
                                  <option value="facebook">Facebook</option>
                                  
                                </select>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </CardContent>
                      <CardActions style={{padding:6}}>
                        <Button
                          variant="contained"
                          onClick={(e) => this.createAccount(e) }
                          // onClick={() => alert('I am Custom Button.')}
                          className={classes.button_submitButton}
                        >
                          Submit
                        </Button>
                      </CardActions>
                    </Card>
                  </Paper>
                </div>
              </form>

            </Grid>
          </Grid>
        </div>
      </>
    )
  };
}

export default withStyles(styles)(EmailAccountRegistration);

