import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { imgBackground, imgLogo, imgMiddle } from "./assets";

import ForgotPasswordController, {
	Props
} from "./ForgotPasswordController";

const styles: any = (theme: any) => ({
	root: {
		flexGrow: 1,
		// backgroundColor: "rgba(255, 255, 255, 1)",
		backgroundImage: `url(${imgBackground})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 100%',
		fontFamily: '"HelveticaNeue-Medium", sans-serif',
		backgroundAttachment: 'fixed',
	},
	sideOne: {
		// background: 'rgba(255, 255, 255, 1)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		padding: 30,
		'& .footer1': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
			// marginTop: 20,
			'& p': {
				fontStyle: 'normal',
				fontWeight: 'normal',
				color: 'rgba(35, 35, 35, 1)',
				fontFamily: '"HelveticaNeue", sans-serif',
				fontSize: 18,
				"@media (max-width:768px)": {
					marginRight: 10
				},
			}
		}
	},
	contentOne: {
		'& .nav': {
			height: 'auto',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			// marginRight: 112,
			padding: 20,
			'& .textlabel_newtoIncluzonText': {
				fontStyle: 'normal',
				fontWeight: 'normal',
				fontSize: '14px',
				fontFamily: '"HelveticaNeue-Medium", sans-serif',
				'& Button': {
					backgroundColor: 'rgba(255, 255, 255, 1)',
					borderRadius: 4,
					boxShadow: 'none',
					width: 100,
					color: 'rgba(3, 3, 3, 1)',
					textAlign: 'center',
					fontFamily: 'HelveticaNeue-Medium',
				}
			}
		},
		'& .loginBox': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			height: '70%',
			marginTop: 82,
			"@media (max-width:1024px)": {
				marginTop: 50,
			},
			'& loginBoxContent': {
				width: '250px',
				height: '250px',
				background: 'red'
			}
		},
	},
	logoRoot: {
		'& .textlabel_welcomeText': {
			fontSize: '24px',
			fontFamily: '"HelveticaNeue-Medium", sans-serif',
		},
		'& image_LogoImage': {
			position: 'relative'
		},
		'& textlabel_InventoreText': {
			fontSize: '18px',
			fontFamily: '"HelveticaNeue-Medium", sans-serif',
			position: 'relative',
		}

	},
	paperRoot: {
		boxShadow: '0 3px 13px 0 rgb(0 0 0 / 20%)',
		transition: ' 0.3s',
	},
	cardRoot: {
		minWidth: 400,
		boxShadow: 'none',
		padding: '8px 90px',
		borderRadius: 36,
		"@media (max-width:1024px)": {
			padding: '0px 30px',
		},
		"@media (max-width:768px)": {
			padding: '0px 0px',
		},
		'& .MuiCardActions-root': {
			display: 'flex',
			padding: 16,
			alignItems: 'self-end',
			justifyContent: 'end',

		},
	},
	cardContent: {
		'& .title': {
			marginBottom: 20
		},
		'& .textlabel_loginText': {
			position: 'relative',
			backgroundColor: 'transparent',
			fontStyle: 'normal',
			fontWeight: 'bold',
			color: 'rgba(33, 33, 33, 1)',
			fontFamily: 'HelveticaNeue-CondensedBold',
			fontSize: 32
		},
		'& .textlabel_creatpasswordText': {
			fontStyle: 'normal',
			fontWeight: 'normal',
			color: 'rgba(89, 89, 89, 0.7046820367132867)',
		},
		'& .footer': {
			'& .textlabel_forgotpasswordText': {
				textTransform: 'none',
				position: 'relative',
				backgroundColor: 'transparent',
				fontStyle: 'normal',
				fontWeight: 'normal',
				color: 'rgba(255, 85, 54, 1)',
				fontFamily: 'HelveticaNeue',
				fontSize: 14,
				padding: 0
			}
		},
		'& .inputBox input': {
			height: 30,
			width: 450,
			"@media (max-width:768px)": {
				width: 350,
			},
			padding: 6,
			marginBottom: 18,
			borderRadius: 3,
			border: '1px solid rgba(89, 89, 89, 0.6)',
			color: 'rgba(89, 89, 89, 0.6)',

		}
	},
	sideImg: {
		width: 290,
		"@media (max-width:768px)": {
			width: 160,
		},
	},
	image_LoginImage: {
		width: '100%',
		margin: 20
	},
	button_savepasswordButton: {
		backgroundColor: 'rgba(1, 129, 123, 1)',
		justifyContent: 'center',
		position: 'relative',
		borderRadius: 6,
		color: 'rgba(255, 255, 255, 1)',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontFamily: 'HelveticaNeue-Medium',
		fontSize: 18,
		textAlign: 'center',
		boxShadow: 'none',
		width: 215
	}

});

class ForgotPassword extends ForgotPasswordController {
	// Customizable Area Start
	// Customizable Area End

	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// const classes = useStyles();

	render() {
		const { classes } = this.props;
		return (
			<>
				<div className={classes.root}
				// style={{backgroundImage: `url(${imgBackground})`}}
				>
					<Grid container >
						<Grid item xs={4} className={classes.sideOne}>
							<div className={classes.logoRoot}>
								<div >
									<Typography gutterBottom className="textlabel_welcomeText" >
										Welcome to
									</Typography>
								</div>

								<img src={imgLogo} className="image_LogoImage" />

								<Typography variant="subtitle2" gutterBottom className="textlabel_InventoreText" >
									Inventore veritas et quasi architetto beata Vitae dicta sunt
									explicabo. Nemo Enim ipsam voluptatem quia voluptas sit
									Aspernatur aut odio aut fugit, sed quia Consequuntur
									inventore veritatis et quasi</Typography>
							</div>
							<div className={classes.sideImg}>
								<img src={imgMiddle} className={classes.image_LoginImage} />
							</div>
							<div className="footer1">
								<p>Contact us</p>
								<p>Terms</p>
								<p>Privacy</p>
							</div>
						</Grid>
						<Grid item xs={8} className={classes.contentOne}>
							<div className="nav">
								<p className="textlabel_newtoIncluzonText">
									New to Incluzon?
									<span style={{ marginLeft: 9 }}>
										<Button variant="contained"
											onClick={() => window.location.href = "/EmailAccountRegistration"}
										>
											Signup
										</Button>
									</span>
								</p>
							</div>
							{this.state.emailField
							?<form>
								<div className="loginBox">
									<Paper elevation={0} className={classes.paperRoot} >
										<Card className={classes.cardRoot}>
											<CardContent>
												<div className={classes.cardContent}>
													<div className="title" >
														<Typography
															className="textlabel_loginText"
														>
															WELCOME TO INCLUZON
														</Typography>
														 <Typography
																className="textlabel_creatpasswordText"
															>
																Verify your Email
															</Typography>
															
													</div>
													<div className="inputBox">
															<input
																type="text"
																id="email"
																name="email"
																placeholder="Enter Email"
																onChange={(e) => this.textInput(e)}
															/>
															{
																this.state.emailError
																	? (
																		<p>ForgotPasswordController.emailRequired</p>
																	)
																	: this.state.emailErrorStatus
																		? (
																			<p>ForgotPasswordController.invaildEmail</p>
																		)
																		: null
															}
														</div>
													
													
												</div>

											</CardContent>
											<CardActions>
												 <Button
															variant="contained"
															onClick={(e) =>
																this.goToOtpAfterEmailValidation(
																	{ accountType: "", email: "" },
																	e
																)
															}
															className={classes.button_savepasswordButton}

														>
															Check Email
														</Button>
									
											</CardActions>
										</Card>
									</Paper>
								</div>
							</form>

							:<form>
								<div className="loginBox">
									<Paper elevation={0} className={classes.paperRoot} >
										<Card className={classes.cardRoot}>
											<CardContent>
												<div className={classes.cardContent}>
													<div className="title" >
														<Typography
															className="textlabel_loginText"
														>
															WELCOME TO INCLUZON
														</Typography>
														
															<Typography
																className="textlabel_creatpasswordText"
															>
																Please create your password
															</Typography>
													

													</div>
													
														<div className="inputBox">

															<input
																type="text"
																id="typePassword"
																name="password"
																placeholder="Type your password"
																onChange={(e) => this.textInput(e)}
															/>
															{
																this.state.passwordError ? (
																	<p>
																		EmailAccountRegistrationController.Password Required
																	</p>
																) : null
															}
															<br />
															<input
																type="text"
																id="confirmPassword"
																name="conformPassword"
																placeholder="Confirm your password"
																onChange={(e) => this.textInput(e)}
															/>
															{
																this.state.reTypePasswordError ? (
																	<p>
																		EmailAccountRegistrationController.confirm Password is Required
																	</p>
																) : this.state.reTypePasswordStatusError ? (
																	<p>
																		EmailAccountRegistrationController.confirmPaswordNotmatch
																	</p>
																) : null
															}
														</div>
													
												</div>

											</CardContent>
											<CardActions>
											
													 <Button
															variant="contained"
															onClick={() => this.goToConfirmationAfterPasswordChangeWeb()}
															className={classes.button_savepasswordButton}
														>
															Save Password
														</Button>
											
											</CardActions>
										</Card>
									</Paper>
								</div>
							</form>
	}
						</Grid>
					</Grid>
				</div>
			</>
		)
	};
}

export default withStyles(styles)(ForgotPassword);

