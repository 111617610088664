import React from "react";
import {
  BrowserRouter,
  Router,
  Route,
  NavLink,
  Switch,
  HashRouter,
} from "react-router-dom";
import { BlockComponent } from "../../framework/src/BlockComponent";
import {
  withStyles, alpha,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardWeb from "../../blocks/dashboard/src/Dashboard.web";
import JobListing2Web from "../../blocks/JobListing2/src/JobListing2.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
const imgLogo = require("./17ff9566777014f21de6cd293132ea606eabda2a.png");
const imgDashboard = require("./3c4c38c55b8e4029dab9d39e57b844ecc17e17cf.png");
const imgStudentsProfile = require("./da2ae7eb09b45789e149e1d4572a6dfd4b4529ae.png");
const imgShortlistedCandidates = require("./296ff24b495327c62231573d444c6414a1c7f701.png");
const imgJobListing = require("./39d695fdb9e40edfedc6f7ef10278c99456dc141.png");
const imgScheduleInterview = require("./08c3a6cc2ad4a293422375a3b807661eae6cb59e.png");
const imgNotifications = require("./d349aea0cd5f807881806825db1df4a74ce194f9.png");
const imgHelp = require("./296ff24b495327c62231573d444c6414a1c7f701.png");
const imgProfile = require("./ca783aaff0e4e21aa8b0b0115c407d5b244b73a9.png");
const imgSearchIcon = require("./4207026d00382d5f2cabbe26693566e678284edb.png");

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import SingletonFactory from '../../framework/src/SingletonFactory';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";

//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()


const styles: any = (theme: any) => ({

  sideandroute: {
    marginTop: 84
  },
  image_LogoImage: {
    position: 'relative'
  },
  appBar: {
    padding: 10,
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: 'none',
    '& .toolBar': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  },
  profile_area: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    '& span': {
      marginTop: 10
    }
  },
  textlabel_profileName: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: 'rgba(33, 33, 33, 1)',
    fontFamily: 'HelveticaNeue-Medium',
    fontSize: 20,
  },
  topbarItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    color: 'gray'
  },
  search: {
    position: 'relative',
    borderRadius: 30,
    width: 250,
    marginRight: 20,
    backgroundColor: 'rgb(243,244,249)',
    // '&:hover': {
    //   backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 45
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%',
      height: 20
    }
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  notificationArea: {
    marginRight: 20,
    '& Badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
    '& img': {
      width: '100%',
      height: 30,
    }
  },
  sideNav: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    // borderRight: " 1px solid #e4e8f0",
    height: "100vh",
    left: "0",
    position: "absolute",
    width: '100%',
    "& .MuiListItem-root.Mui-selected": {
      backgroundColor: 'rgba(1, 129, 123, 1)',
      borderLeft: '3px solid yellow',
      color: 'rgba(255, 255, 255, 1)',
    }
  },

  sideNavItem: {
    color: 'rgba(33, 33, 33, 1)',
    '&:hover': {
      backgroundColor: 'rgba(1, 129, 123, 1)',
      borderLeft: '3px solid yellow',
      color: 'rgba(255, 255, 255, 1)',
    },

  },
  sideNavIcon: {
    marginRight: 20,
    marginLeft: 10,
    width: 23,
    '& img': {
      width: '100%'
    }
  },
  text_style: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontFamily: 'HelveticaNeue-Medium, sans-serif',
    fontSize: 17,
  },
  routingPages: {
    margin: 30,
    background: 'rgb(243,244,249)'
  }
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}

interface sideNavData {
  primary: String,
  icon: String

}

// Customizable Area Start
interface S {
  selectedIndex: number;
  anchorEl: null;
}

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS>  {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      selectedIndex: 1,
      anchorEl: null,
    }
    // Customizable Area End
  }
  handleListItemClick = (event: any, index: any, str?: any) => {
    this.setState({
      selectedIndex: index,
      // activeStatus: str,
    });
  };
  handleMenu = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    // this.loadUserDetailsAndRoles()
    const { classes } = this.props;
    return (
      <>
        <Grid className={classes.navArea}>
          <AppBar position="fixed" className={classes.appBar} >
            <Toolbar className="toolBar">
              <Grid>
                <img src={imgLogo} className={classes.image_LogoImage} />
              </Grid>
              <Grid className={classes.topbarItems}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <img src={imgSearchIcon} />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div>
                <div className={classes.notificationArea}>
                  <Badge badgeContent={4} color="secondary">
                    <img src={imgNotifications} />
                  </Badge>
                </div>
                <div className={classes.profileIcon}>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                  >
                    <Avatar alt="Remy Sharp" src={imgProfile} />
                    <div className={classes.profile_area}>
                      <Typography className={classes.textlabel_profileName}>Ricky Ponting</Typography>
                      <span><ExpandMoreIcon /></span>
                    </div>
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                  >
                    <MenuItem onClick={this.handleClose}>Profile</MenuItem>
                    <MenuItem onClick={this.handleClose}>My account</MenuItem>
                  </Menu>
                </div>
              </Grid>
            </Toolbar>
          </AppBar>
        </Grid>
        <div className={classes.sideandroute}>
          <BrowserRouter>
            <Grid container >
              <Grid style={{ position: 'relative', width: '23%' }} >
                <div className={classes.sideNav}>
                  <List
                    component="nav"
                    className={classes.sideNavItems}
                  >
                    <NavLink
                      to="/home/dashboard"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem
                        button
                        selected={this.state.selectedIndex === 1}
                        onClick={(event) => this.handleListItemClick(event, 1)}
                        className={classes.sideNavItem}
                      >
                        <div className={classes.sideNavIcon}>
                          <img src={imgDashboard} />
                        </div>
                        <Typography className={classes.text_style}>Dashboard</Typography>
                        {/* <ListItemText primary="Dashboard"  /> */}
                      </ListItem>
                    </NavLink>
                    <NavLink
                      to="/home/dashboard"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem
                        button
                        selected={this.state.selectedIndex === 2}
                        onClick={(event) => this.handleListItemClick(event, 2)}
                        className={classes.sideNavItem}
                      >
                        <div className={classes.sideNavIcon}>
                          <img src={imgStudentsProfile} />
                        </div>
                        <Typography className={classes.text_style}>Students Profile</Typography>
                        {/* <ListItemText primary="Students Profile" /> */}
                      </ListItem>
                    </NavLink>
                    <NavLink
                      to="/home/dashboard"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem
                        button
                        selected={this.state.selectedIndex === 3}
                        onClick={(event) => this.handleListItemClick(event, 3)}
                        className={classes.sideNavItem}
                      >
                        <div className={classes.sideNavIcon}>
                          <img src={imgShortlistedCandidates} />
                        </div>
                        <Typography className={classes.text_style}>Shortlisted Candidates</Typography>
                        {/* <ListItemText primary="Shortlisted Candidates" /> */}
                      </ListItem>
                    </NavLink>
                    <NavLink
                      to="/home/joblisting"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem
                        button
                        selected={this.state.selectedIndex === 4}
                        onClick={(event) => this.handleListItemClick(event, 4)}
                        className={classes.sideNavItem}
                      >
                        <div className={classes.sideNavIcon}>
                          <img src={imgJobListing} />
                        </div>
                        <Typography className={classes.text_style}>Job Listing</Typography>
                        {/* <ListItemText primary="Job Listing" /> */}
                      </ListItem>
                    </NavLink>
                    <NavLink
                      to="/home/dashboard"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem
                        button
                        selected={this.state.selectedIndex === 5}
                        onClick={(event) => this.handleListItemClick(event, 5)}
                        className={classes.sideNavItem}
                      >
                        <div className={classes.sideNavIcon}>
                          <img src={imgScheduleInterview} />
                        </div>
                        <Typography className={classes.text_style}>Schedule Interview</Typography>
                        {/* <ListItemText primary="Schedule Interview" /> */}
                      </ListItem>
                    </NavLink>
                    <NavLink
                      to="/home/dashboard"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem
                        button
                        selected={this.state.selectedIndex === 6}
                        onClick={(event) => this.handleListItemClick(event, 6)}
                        className={classes.sideNavItem}
                      >
                        <div className={classes.sideNavIcon}>
                          <img src={imgNotifications} />
                        </div>
                        <Typography className={classes.text_style}>Notifications</Typography>
                        {/* <ListItemText primary="Notifications" /> */}
                      </ListItem>
                    </NavLink>
                    <NavLink
                      to="/home/dashboard"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem
                        button
                        selected={this.state.selectedIndex === 7}
                        onClick={(event) => this.handleListItemClick(event, 7)}
                        className={classes.sideNavItem}
                      >
                        <div className={classes.sideNavIcon}>
                          <img src={imgHelp} />
                        </div>
                        <Typography className={classes.text_style}>Help & Support</Typography>
                        {/* <ListItemText primary="Help & Support" /> */}
                      </ListItem>
                    </NavLink>
                  </List>
                </div>
              </Grid>
              <Grid style={{ background: 'rgb(243,244,249)', width: '77%' }} >
                <div className={classes.routingPages} >
                  <Switch>
                    <Route
                      exact
                      path="/home/dashboard"
                      component={DashboardWeb}
                    />
                    <Route
                      exact
                      path="/home/joblisting"
                      component={JobListing2Web}
                    />
                  </Switch>
                </div>
              </Grid>
            </Grid>
          </BrowserRouter>
        </div>
      </>

    );
  }
}
// Customizable Area End
export default withStyles(styles)(HomeScreen);
