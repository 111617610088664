import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Chart from "react-google-charts";


const styles: any = (theme: any) => ({

    pie_root: {
        height:270,
        boxShadow: '3px 4px 10px -5px',
        padding:20
    },
    textlabel_Text: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: 'rgba(33, 33, 33, 1)',
        fontFamily: 'HelveticaNeue-Medium',
        fontSize: 20,
        margin: '20px 0px'
      },
    chart: {
        width: "700px",
        height: "500px",
        position: "relative",
        bottom: "70px"
    }

});

interface Props {
    classes?:any;
}

class Pie extends React.Component <Props>{
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // const classes = useStyles();


    render() {
        const { classes } = this.props;

        const data: any = [
            ['Task', 'Hours per Day'],
            ['Work', 11],
            ['Eat', 2],
            ['Commute', 2],
            ['Watch TV', 2],
            ['Sleep', 7]
        ]

        return (
            <>
                <div>
                    <Card className={classes.pie_root}  >
                    <Typography className={classes.textlabel_Text} >Gender</Typography>
                           
                                <Chart
                                    // className={classes.chart}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={data}
                                    rootProps={{ "data-testid": "1" }}
                                />
                        
                    </Card>
                </div>
            </>
        )
    };
}

export default withStyles(styles)(Pie);