import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";


const styles: any = (theme: any) => ({

  geochart_root: {
    height: 270,
    boxShadow: '3px 4px 10px -5px',
    padding: 20
  },
  textlabel_Text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: 'rgba(33, 33, 33, 1)',
    fontFamily: 'HelveticaNeue-Medium',
    fontSize: 20,
    margin: '20px 0px'
  },

  img: {
    width: '100%'
  }

});

interface Props {
  classes?:any;
}

class Geochart extends React.Component<Props> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // const classes = useStyles();


  render() {
    const { classes } = this.props;

    return (
      <>
        <div>
          <Card className={classes.geochart_root}  >
            <Typography className={classes.textlabel_Text} >Gujarat</Typography>
            <img className={classes.img} src="https://diligentias.com/wp-content/uploads/2019/05/gujarat.png"></img>

          </Card>
        </div>
      </>
    )
  };
}

export default withStyles(styles)(Geochart);