import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  fullName: string;
  fullNameError: boolean;
  companyName: string;
  companyNameError: boolean;
  email: string;
  emailError: boolean;
  emailStatusError: boolean;
  password: string;
  passwordError: boolean;
  passwordStatusError: boolean;
  anyEnquiry: string;
  companySize: string;
  social_media: string;
  otpAuthToken: string;
  reTypePassword: string;
  reTypePasswordError: boolean;
  reTypePasswordStatusError: boolean;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  phoneError: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      fullName: "",
      companyName: "",
      email: "",
      password: "",
      reTypePassword: "",
      anyEnquiry: "",
      companySize: "",
      social_media: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",

      fullNameError:false,
      companyNameError:false,
      emailError:false,
      emailStatusError:false,
      passwordError:false,
      passwordStatusError:false,
      reTypePasswordError:false,
      reTypePasswordStatusError:false,
      phoneError:false

      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.createAccountApiCallId
    && getName(MessageEnum.RestAPIResponceMessage) === message.id  ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
console.log(responseJson, "responseJson......")

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (message.properties?.RestAPIResponceSuccessMessage?.data != null){
        localStorage.setItem("userEmail", this.state.email)
        this.props.navigation.navigate('EmailVerfication')
      }


      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          // if (this.arrayholder && this.arrayholder.length !== 0) {
          //   let regexData = this.arrayholder[0];

          //   if (regexData.password_validation_regexp) {
          //     this.passwordReg = new RegExp(
          //       regexData.password_validation_regexp
          //     );
          //   }

          //   if (regexData.password_validation_rules) {
          //     this.setState({
          //       passwordHelperText: regexData.password_validation_rules
          //     });
          //   }

          //   if (regexData.email_validation_regexp) {
          //     this.emailReg = new RegExp(regexData.email_validation_regexp);
          //   }
          // }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // else if(message.getData(getName(MessageEnum.RestAPIResponceDataMessage))== this.createAccountApiCallId){

    //   localStorage.removeItem("userEmail")
    //   this.props.navigation.navigate('EmailAccountLoginBlock')
    // }

    if (message.properties?.RestAPIResponceSuccessMessage?.meta != null) {

      console.log(message.properties?.RestAPIResponceSuccessMessage?.data, "userInfo")
      localStorage.setItem("userInfo", JSON.stringify(message.properties?.RestAPIResponceSuccessMessage?.data?.attributes))
      localStorage.setItem("userId", message.properties?.RestAPIResponceSuccessMessage?.data?.id)
      localStorage.setItem("token", message.properties?.RestAPIResponceSuccessMessage?.meta?.token)
      let userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
      let role = userInfo?.role?.data?.attributes?.name;
    
      window.location.assign("/home/dashboard");

    }
    
    // if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
    //   const otpAuthTkn = message.getData(
    //     getName(MessageEnum.AuthTokenDataMessage)
    //   );
    //   if (otpAuthTkn && otpAuthTkn.length > 0) {
    //     this.setState({ otpAuthToken: otpAuthTkn });
    //     runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
    //     runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    //   }
    // }

    // if (getName(MessageEnum.CountryCodeMessage) === message.id) {
    //   var selectedCode = message.getData(
    //     getName(MessageEnum.CountyCodeDataMessage)
    //   );

    //   if (selectedCode !== undefined) {
    //     this.setState({
    //       countryCodeSelected:
    //         selectedCode.indexOf("+") > 0
    //           ? selectedCode.split("+")[1]
    //           : selectedCode
    //     });
    //   }
    // }
    // Customizable Area End
  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  txtInput(e: any) {
    if (e.target.name === "fullName") {
      if (e.target.value === '') {
        this.setState({
          fullNameError: true,
        
        })
      }
      else {
        this.setState({
          fullNameError: false
        })
      }
      this.setState({
        fullName: e.target.value
      })

    }
    if (e.target.name === "companyName") {
      if (e.target.value === '') {
        this.setState({
          // firstNameError:false,
          companyNameError: true,
          // emailError:false,
          // emailStatusError:false,
          // companyNameError:false,
          // passwordStatusError:false,
          // passwordError:false,
          // reTypePasswordStatusError:false,
          // reTypePasswordError:false
        })
      }
      else {
        this.setState({
          companyNameError: false
        })

      }
      this.setState({
        companyName: e.target.value
      })
    }
    if (e.target.name === "email") {
      if (e.target.value === '') {
        this.setState({
          // firstNameError:false,
          // lastNameError:false,
          emailError: true,
          emailStatusError: false,
          // companyNameError:false,
          // passwordStatusError:false,
          // passwordError:false,
          // reTypePasswordStatusError:false,
          // reTypePasswordError:false
        })
      }
      if (e.target.value.length > 0) {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+))|("[\w-\s]+")([\w-]+(?:\.[\w-]+)))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(e.target.value)) {

          this.setState({
            // firstNameError:false,
            // lastNameError:false,
            emailError: false,
            emailStatusError: true,
            // companyNameError:false,
            // passwordStatusError:false,
            // passwordError:false,
            // reTypePasswordStatusError:false,
            // reTypePasswordError:false
          })



        }

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+))|("[\w-\s]+")([\w-]+(?:\.[\w-]+)))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (pattern.test(e.target.value) && e.target.value !== "") {
          this.setState({
            emailError: false,
            emailStatusError: false,
          })

        }
      }

      this.setState({
        email: e.target.value
      })
    }
    if (e.target.name === "password") {
      var patternPassword = new RegExp(/^(?=.[A-Z])(?=.[#!@$&?<>',\[\]}{=\-)(^%`~+.:;_])(?=.[0-9])(?=.*[a-z]).{8,}$/);
      if (e.target.value === '') {
        this.setState({
          passwordError: true,
          passwordStatusError: false
        })
      }

      if (e.target.value.length > 6) {
        this.setState({
          passwordStatusError: false
        })
       
      }
      else{
        this.setState({
          passwordStatusError: true
        })
      }
      this.setState({
        password: e.target.value
      })
    }
    if (e.target.name === "phone") {
      if (e.target.value === '') {
        this.setState({
          phoneError: true,
        })
      }
      else {
        this.setState({
          phoneError: false
        })

      }
      this.setState({
        phone: e.target.value
      })
    }
    if (e.target.name === "conformPassword") {
      if (e.target.value === '') {
        this.setState({
          reTypePasswordError: true,
          reTypePasswordStatusError: false
        })
      }

      if (e.target.value !== '' && e.target.value != this.state.password) {
        this.setState({
          reTypePasswordError: false,
          reTypePasswordStatusError: true
        })
      }
      if (e.target.value != '' && e.target.value === this.state.password) {

        this.setState({
          reTypePasswordError: false,
          reTypePasswordStatusError: false
        })
      }
      this.setState({
        reTypePassword: e.target.value
      })
    }
    if (e.target.name === "anyEnquiry") {
      this.setState({
        anyEnquiry: e.target.value
      })
    }
    if (e.target.name === "companySize") {
      this.setState({
        companySize: e.target.value
      })
    }
    if (e.target.name === "hearAbout") {
      this.setState({
        social_media: e.target.value
      })
    }
  }

    createAccount(e?: any) {
      e.preventDefault();
      if (this.state.fullName === '') {
        this.setState({
          fullNameError: true
        })
      }
     
      if (this.state.email === '') {
        this.setState({
          emailError: true
        })
      }
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+))|("[\w-\s]+")([\w-]+(?:\.[\w-]+)))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  
      if (pattern.test(this.state.email) && this.state.email !== "") {
        this.setState({
          emailError: false,
          emailStatusError: false,
        })
  
      }
      if (this.state.companyName === '') {
        this.setState({
          companyNameError: true
        })
      }
  
      // var patternPassword = new RegExp(/^(?=.[A-Z])(?=.[#!@$&?<>',\[\]}{=\-)(^%`~+.:;_])(?=.[0-9])(?=.*[a-z]).{8,}$/);
      if (this.state.password === '') {
        this.setState({
          passwordError: true,
          passwordStatusError: false
        })
      }
      
      // else if (!patternPassword.test(this.state.password)) {
      //   this.setState({
  
      //     passwordError: false,
      //     passwordStatusError: true
      //   })
      // }
     
      else if (this.state.password.length > 6){
        this.setState({
          passwordStatusError: false,
          passwordError: false,
        })
      }
      if (this.state.reTypePassword === '') {
        this.setState({
          reTypePasswordError: true
        })
      }
      if (this.state.reTypePassword !== '' && this.state.reTypePassword != this.state.password) {
        this.setState({
          reTypePasswordStatusError: true
        })
      }

      if (this.state.fullName != "" && this.state.email != "" && this.state.companyName !== '' && this.state.password !== '' &&  this.state.reTypePassword !== '' && this.state.reTypePasswordStatusError === false && this.state.phone !== '') {
        const header = {
          "Content-Type": configJSON.contentTypeApiAddDetail
        };
  
        const attrs = {
          full_name: this.state.fullName,
          company_size: this.state.companySize,
          company_name: this.state.companyName,
          company_email: this.state.email,
          password: this.state.password,
          password_confirmation: this.state.reTypePassword,
          enquiry_brief: this.state.anyEnquiry,
          social_media: this.state.social_media,
          phone_number: this.state.phone
          // full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
        };
        const data = {
          // type: "email_account",
          attributes: attrs
        };

        const httpBody = {
          data: data,
          // token: this.state.otpAuthToken,
          // language: localStorage.getItem("i18nextLng") == 'en' ? 'English' : 'German'
        };
        console.log(httpBody, "httpBody....")
  
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createAccountApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.accountsAPiEndPointt
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiMethodTypeAddDetail
        );
  
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }
  
    }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ companyName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ fullName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // Customizable Area End
}
