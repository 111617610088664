import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  departmentName:string;
  location:string;
  jobtitle:string;
  minimumEducation:string;
  isJoin:string;
  isJoindate:string;
  is_application:string;
  applicationDeadline:string;
  remotelyPerformed:string;
  numberOfHires:string;
  jobStatus:string;
  jobDescription:string;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class JobListing2Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  jobListingCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      departmentName:"",
  location:"",
  jobtitle:"",
  minimumEducation:"",
  isJoin:"",
  isJoindate:"",
  is_application:"",
  applicationDeadline:"",
  remotelyPerformed:"",
  numberOfHires:"",
  jobStatus:"",
  jobDescription:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    console.log(message, "message....")

    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    console.log('@@@@@@@@@@@@@@@@@@@@', responseJson);
    }

    // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
    //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    //   this.showAlert(
    //     "Change Value",
    //     "From: " + this.state.txtSavedValue + " To: " + value
    //   );

    //   this.setState({ txtSavedValue: value });
    // }

    // Customizable Area Start
    // Customizable Area End

  }

  txtInput(e: any) {

    const name = e.target.name
    const text = e.target.value
    if (e.target.name === "departmentName") {

      this.setState({
        departmentName: text
      })
    }
    if (e.target.name === "location") {
     
      this.setState({
        location: text
      })
    }
    if (e.target.name === "jobtitle") {
     
      this.setState({
        jobtitle: text
      })
    }
    if (e.target.name === "minimumEducation") {
     
      this.setState({
        minimumEducation: text
      })
    }
    if (e.target.name === "isJoin") {
     
      this.setState({
        isJoin: text
      })
    }
    if (e.target.name === "isJoindate") {
     
      this.setState({
        isJoindate: text
      })
    }
    if (e.target.name === "is_application") {
     
      this.setState({
        is_application: text
      })
    }
    if (e.target.name === "applicationDeadline") {
     
      this.setState({
        applicationDeadline: text
      })
    }
    if (e.target.name === "remotelyPerformed") {
     
      this.setState({
        remotelyPerformed: text
      })
    }
    if (e.target.name === "numberOfHires") {
     
      this.setState({
        numberOfHires: text
      })
    }
    if (e.target.name === "jobStatus") {
     
      this.setState({
        jobStatus: text
      })
    }
    if (e.target.name === "jobDescription") {
     
      this.setState({
        jobDescription: text
      })
    }
  }

  createAccount(e?: any) {
    e.preventDefault();

  const token = localStorage.getItem("token");
  console.log(token, "token,,,,,,,,,,,,")
  const header = {
    "Content-Type": configJSON.exampleApiContentType,
    'token': `${token}`
  };

    const attrs = {
      department_name:  this.state.departmentName,
      job_title:  this.state.jobtitle,
      location: this.state.location,
      min_education:  this.state.minimumEducation,
      is_joining_date_present:  this.state.isJoin,
      joining_date: this.state.isJoindate,
      is_application_deadline_present: this.state.is_application,
      application_deadline: this.state.applicationDeadline,
      is_remotely_performed: this.state.remotelyPerformed,
      hires_count: this.state.numberOfHires,
      job_description: this.state.jobDescription,
      job_status: this.state.jobStatus,
    };

    const httpBody = attrs

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.jobListingCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exampleAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  // Customizable Area End
  
}
