import React from "react";
import { withStyles } from '@material-ui/core/styles';
import DashboardController, { Props } from "./DashboardController";
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import Graph from "./Graph";
import Pie from "./Pie";
import Area from "./Area";
import GeoChart from "./Geochart";
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from '@material-ui/core/LinearProgress';
// import profilePic from '../assets/ca783aaff0e4e21aa8b0b0115c407d5b244b73a9.png';

const styles: any = (theme: any) => ({
  root: {
    // margin: '30px 0px 30px 50px',
    background: 'rgba(255, 255, 255, 1)',
    padding: 20,
    borderRadius: 20,
    boxShadow: '2px 3px 10px 3px rgb(0 0 0 / 20%)',
  },
  textlabel_dashboard: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: 'rgba(23, 23, 37, 1)',
    fontFamily: 'HelveticaNeue-Medium',
    fontSize: 24,
  },
  card_component: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '30px 0px',
  },
  card_info: {
    boxShadow: 'none',
    padding: '10px',
    width: 170,
    margin: 5
  },
  count: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  textprofileRoll:{
    position: 'absolute',
    top: -30,
    width: 255,
    right: 28,
    background: 'rgb(87,192,226)',
    color: 'white',
    borderRadius:'0px 30px 0px 0px',
    textAlign:'center',
    padding:3
  },
  avatar:{
    position: 'absolute',
    top: -39,
    left: 20,
    display: 'flex',
    alignItems:'end',
    justifyContent: 'space-between',
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  profileName:{
    marginLeft:20,
    color: 'rgba(23, 23, 37, 1)',
    fontSize: 18,
    fontStyle: 'normal',
    fontFamily: '"HelveticaNeue-Medium", sans-serif',
    fontWeight: 600,
    lineHeight:1,
  },
  profileNamespan:{
    color: 'rgba(89, 89, 89, 0.6)',
    fontSize: 14,
    fontStyle: 'normal',
    fontFamily: '"HelveticaNeue", sans-serif',
    fontWeight: 'normal',
  },
  fitmentProgressRoot:{
    display: 'flex',
    justifyContent: 'space-between',
  },
  fitmentProgress:{
    marginTop: 10,
    width: '70%',
    '& .MuiLinearProgress-colorPrimary':{
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    height: 7,
    borderRadius: 50
    },
    '& .MuiLinearProgress-barColorPrimary':{
      backgroundColor:'rgb(118,230,108)'
    }
  },
  fitmentScore:{
    color:'rgb(118,230,108)'
  },
  profile_info:{
    marginTop:45,
    fontSize: 20,
    fontFamily: '"HelveticaNeue", sans-serif',
  },
  profile_infoData:{
    fontSize: 16,
    fontFamily: '"HelveticaNeue", sans-serif',
    color:'rgba(23, 23, 37, 1)',
    fontWeight:'bold'
  },
  textlabel_Text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: 'rgba(33, 33, 33, 1)',
    fontFamily: '"HelveticaNeue-Medium", sans-serif',
    fontSize: 20,
    margin: '20px 0px',
    "@media (max-width:1024px)": {
      fontSize: 14,
    },
  },
  newEnrollmentDataCard:{
    background: 'rgba(255, 255, 255, 1)',
    padding: 20,
    borderRadius: 20,
    boxShadow: '5px 1px 25px 0px rgb(0 0 0 / 20%)',
  },
  col: {
    display: 'inline-block',
    margin: '0 5px',
    transform: 'scale(0.8)',
  },
  skills:{
    marginTop:20,
  },
  skillsItem:{
    background: 'rgba(0, 0, 0, 0.08)',
    borderRadius: 50,
    textAlign: 'center',
    padding: 5,
    '& p':{
    fontSize: 14,
    fontWeight: 500,
    fontFamily: '"HelveticaNeue", sans-serif',
    }
  }
 
});


class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  render() {
    const arr: any = [102, 222, 333, 444, 555, 666];
    const { classes } = this.props;
    const col = <span className={classes.col}>:</span>;
    
    return (
      <>
        <div className={classes.root}>
          <Typography className={classes.textlabel_dashboard}>Dashboard</Typography>
          <Divider />
          <Grid className={classes.card_component}>
            <Card className={classes.card_info} style={{ background: 'blue' }} >
              <div>
                <div className={classes.count} >
                  <Typography>102</Typography>
                </div>
                <div>
                  <Typography>Total</Typography>
                  <Typography>Candidates</Typography>
                </div>

              </div>
            </Card>
            <Card className={classes.card_info} style={{ background: 'red' }} >
              <div>
                <div className={classes.count} >
                  <Typography>012</Typography>
                </div>
                <div>
                  <Typography>New</Typography>
                  <Typography>Candidates</Typography>
                </div>
              </div>
            </Card>
            <Card className={classes.card_info} style={{ background: 'blue' }} >
              <div>
                <div className={classes.count} >
                  <Typography>458</Typography>
                </div>
                <div>
                  <Typography>Man</Typography>
                  <Typography>hour Saved</Typography>
                </div>
              </div>
            </Card>
            <Card className={classes.card_info} style={{ background: 'yellow' }} >
              <div>
                <div className={classes.count} >
                  <Typography>084</Typography>
                </div>
                <div>
                  <Typography>Students</Typography>
                  <Typography>Shortlisted</Typography>
                </div>
              </div>
            </Card>
            <Card className={classes.card_info} style={{ background: 'green' }} >
              <div>
                <div className={classes.count} >
                  <Typography>041</Typography>
                </div>
                <div>
                  <Typography>Selected </Typography>
                  <Typography>for Interview</Typography>
                </div>
              </div>
            </Card>
            <Card className={classes.card_info} style={{ background: 'green' }} >
              <div>
                <div className={classes.count} >
                  <Typography>012</Typography>
                </div>
                <div>
                  <Typography>Offer letter</Typography>
                  <Typography>Given</Typography>
                </div>
              </div>
            </Card>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Grid className={classes.graph}>
                <Graph />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.pie}>
                <Pie />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{marginBottom:10}} >
            <Grid item xs={8}>
              <Grid className={classes.area}>
              <Area />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.geoChart}>
              <GeoChart />
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid container >
            <div>
             <div>
             <Typography className={classes.textlabel_Text} >New enrollments</Typography>
             </div>
             <div style={{position:'relative'}}>
             <Card className={classes.newEnrollmentDataCard} >
               <div className={classes.textprofileRoll}>
                 <Typography>React Native Developer</Typography>
               </div>
               <div className={classes.avatar}>
               <Avatar alt="Remy Sharp" src="" className={classes.large} />
               <Typography className={classes.profileName} >First name and last name
               <br />
               <span className={classes.profileNamespan} >(B.Tech)</span>
               </Typography>
               </div>
               <div className={classes.profile_info}>
                 <Typography >Indian Institute of Information and technology</Typography>
                 <Typography className={classes.profileNamespan} >Graduation year{col}
                 <span className={classes.profile_infoData} >2017</span></Typography>
                 <Typography className={classes.profileNamespan} >Availabilty{col}
                 <span className={classes.profile_infoData} >Immediate</span></Typography>
                 <div>
                   <Typography className={classes.profileNamespan} >Fitment score</Typography>
                   <div className={classes.fitmentProgressRoot}>
                   <div className={classes.fitmentProgress}>
      <LinearProgress variant="determinate" value={70} />
      </div>
      <div> <Typography className={classes.fitmentScore}>8.5</Typography></div>
      </div>
                 </div>
                 <div className={classes.skills}>
                 <Grid container>
                 <Grid item xs={6} sm={3} className={classes.skillsItem}  ><Typography>pyhton</Typography></Grid>
                 <Grid item xs={6} sm={3} className={classes.skillsItem}  ><Typography>SQL</Typography></Grid>
                 <Grid item xs={6} sm={3} className={classes.skillsItem}  ><Typography>Excel</Typography></Grid>
                 <Grid item xs={6} sm={3} className={classes.skillsItem}  ><Typography>Reactjs</Typography></Grid>
                 <Grid item xs={6} sm={3} className={classes.skillsItem}  ><Typography>pyhton</Typography></Grid>
                 <Grid item xs={6} sm={3} className={classes.skillsItem}  ><Typography>SQL</Typography></Grid>
                 </Grid>
                 </div>
               </div>
             </Card>
             </div>
             </div>
          </Grid>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Dashboard);