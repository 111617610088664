import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Chart from "react-google-charts";


const styles: any = (theme: any) => ({
    graph_root: {
        height:270,
        boxShadow: '3px 4px 10px -5px',
        padding:20
    },
    textlabel_Text: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: 'rgba(33, 33, 33, 1)',
        fontFamily: 'HelveticaNeue-Medium',
        fontSize: 20,
        margin: '20px 0px'
      },
});

interface Props {
    classes?:any;
}

class Graph extends React.Component<Props> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // const classes = useStyles();


    render() {
        const { classes } = this.props;
        var data: any = [
            ['Year', '', { role: 'style' }],
            ['2010', 10, 'color: gray'],
            ['2020', 14, 'color: #76A7FA'],
            ['2030', 16, 'opacity: 0.2'],
            ['2040', 22, 'stroke-color: #703593; stroke-width: 4; fill-color: #C5A5CF'],
            ['2050', 28, 'stroke-color: #871B47; stroke-opacity: 0.6; stroke-width: 8; fill-color: #BC5679; fill-opacity: 0.2']
        ]

        return (
            <>
                <div>
                    <Card className={classes.graph_root}  >
                    <Typography className={classes.textlabel_Text} >Graph</Typography>
                                <Chart
                                    // style={{ position: "relative", top: "27px" }}
                                    // width={"750px"}
                                    // height={"270px"}
                                    chartType="Bar"
                                    loader={<div>Loading Chart</div>}
                                    data={data}
                                    rootProps={{ "data-testid": "2" }}
                                />
                        
                    </Card>
                </div>
            </>
        )
    };
}

export default withStyles(styles)(Graph);