import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  emailField: Boolean;
  emailError: boolean;
  emailErrorStatus: boolean;
  email: any;
  reTypePassword: string;
  reTypePasswordError: boolean;
  reTypePasswordStatusError: boolean;
  password: string;
  passwordError: boolean;
  passwordStatusError: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const passwordInvisibleImage = require("../assets/ic_password_invisible.png");
const passwordVisibleImage = require("../assets/ic_password_visible.png");
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean;

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  passwordVisibleImage: any = passwordVisibleImage;
  passwordInvisibleImage: any = passwordInvisibleImage;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired)
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired)
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number()
        .min(4)
        .required(configJSON.otpCodeIsRequired)
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          )
        })
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      emailField: true,
      emailError : false,
      emailErrorStatus : false,
      email: "",
      reTypePassword: "",
      reTypePasswordError:false,
      reTypePasswordStatusError:false,
      password: "",
      passwordError: false,
      passwordStatusError: false
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
      // Customizable Area Start
    console.log(message,"message..")

    if(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.requestEmailOtpCallId){
      if (message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))){
        if(!message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)).errors){
           this.setState({
             emailField:false
           })
        console.log(this.state.emailField)
        }
      }
    }

    if(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.requestGoToConfirmationCallId){
      console.log(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)),this.requestGoToConfirmationCallId,"testttt");
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({token: responseJson?.meta?.token});

            console.log('@@@@@@@@@@@@@@@@@@@@', responseJson);

            if (message.properties?.RestAPIResponceSuccessMessage?.meta != null) {

              console.log(message.properties?.RestAPIResponceSuccessMessage?.data, "userInfo")
              localStorage.setItem("userInfo", JSON.stringify(message.properties?.RestAPIResponceSuccessMessage?.data?.attributes))
              localStorage.setItem("userId", message.properties?.RestAPIResponceSuccessMessage?.data?.id)
              localStorage.setItem("token", message.properties?.RestAPIResponceSuccessMessage?.meta?.token)
              let userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
              let role = userInfo?.role?.data?.attributes?.name;
      
            
              window.location.assign("/home/dashboard");
      
            }


            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
    }

    // if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
    //   const otpAuthTkn = message.getData(
    //     getName(MessageEnum.AuthTokenDataMessage)
    //   );

    //   if (otpAuthTkn && otpAuthTkn.length > 0) {
    //     this.setState({ token: otpAuthTkn });
    //     if (this.isChangePassword) {
    //       this.setState({ accountStatus: "ChangePassword" });
    //     }
    //     this.otpToken = this.state.token;
    //     // runEngine.debugLog("otpAuthTkn", this.state.token);
    //   } else {
    //     const accountType = message.getData(
    //       getName(MessageEnum.NavigationForgotPasswordPageInfo)
    //     );
    //     if (accountType) {
    //       this.startForgotPassword(accountType);
    //     }
    //   }
    // } else if (
    //   getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    //   this.validationAPICallId &&
    //   this.validationAPICallId ===
    //     message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    // ) {
    //   var responseJson = message.getData(
    //     getName(MessageEnum.RestAPIResponceSuccessMessage)
    //   );

    //   if (responseJson === undefined) {
    //     return;
    //   }

    //   if (responseJson && responseJson.data[0]) {
    //     const passRegex = RegExp(
    //       responseJson.data[0].password_validation_regexp
    //     );
    //     const emailRegex = RegExp(responseJson.data[0].email_validation_regexp);
    //     const passwordRulesFromValidation =
    //       responseJson.data[0].password_validation_rules;

    //     this.setState({
    //       //email schema
    //       emailSchema: {
    //         email: Yup.string()
    //           .email(configJSON.pleaseEnterAValidEmail)
    //           .required(configJSON.emailIsRequired)
    //           .matches(emailRegex, configJSON.invalidEmailAddress)
    //       },
    //       //password schema
    //       passwordSchema: {
    //         password: Yup.string()
    //           .required(configJSON.pleaseEnterAPassword)
    //           .matches(passRegex, configJSON.invalidPassword),

    //         confirmPassword: Yup.string()
    //           .required(configJSON.pleaseConfirmYourPassword)
    //           .when("password", {
    //             is: val => (val && val.length > 0 ? true : false),
    //             then: Yup.string().oneOf(
    //               [Yup.ref("password")],
    //               configJSON.passwordsMustMatch
    //             )
    //           })
    //       },
    //       passwordRules: passwordRulesFromValidation
    //     });
    //   }
    // } else if (
    //   getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    //   this.requestEmailOtpCallId !== null &&
    //   this.requestEmailOtpCallId ===
    //     message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    // ) {
    //   // console.log("entered email!!!!");
    //   var responseJson = message.getData(
    //     getName(MessageEnum.RestAPIResponceSuccessMessage)
    //   );

    //   if (
    //     responseJson !== undefined &&
    //     responseJson.meta &&
    //     responseJson.meta.token
    //   ) {
    //     this.otpToken = responseJson.meta.token;

    //     this.setState({ token: this.otpToken });

    //     //navigate to OTP page
    //     const msg: Message = new Message(
    //       getName(MessageEnum.NavigationMobilePhoneOTPMessage)
    //     );

    //     msg.addData(
    //       getName(MessageEnum.AuthTokenDataMessage),
    //       this.state.token
    //     );

    //     msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    //     msg.addData(
    //       getName(MessageEnum.AuthTokenEmailMessage),
    //       this.state.emailValue
    //     );

    //     msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

    //     this.send(msg);
    //   }
    //   //error handling
    //   else if (responseJson && responseJson.errors) {
    //     this.parseApiErrorResponse(responseJson);
    //   } else {
    //     var errorReponse = message.getData(
    //       getName(MessageEnum.RestAPIResponceErrorMessage)
    //     );

    //     this.parseApiCatchErrorResponse(errorReponse);
    //   }
    // } else if (
    //   getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    //   this.requestPhoneOtpCallId !== null &&
    //   this.requestPhoneOtpCallId ===
    //     message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    // ) {
    //   // console.log("entered phone!!!!");
    //   var responseJson = message.getData(
    //     getName(MessageEnum.RestAPIResponceSuccessMessage)
    //   );

    //   if (
    //     responseJson !== undefined &&
    //     responseJson.meta &&
    //     responseJson.meta.token
    //   ) {
    //     this.otpToken = responseJson.meta.token;
    //     this.setState({ token: this.otpToken });

    //     const msg: Message = new Message(
    //       getName(MessageEnum.NavigationMobilePhoneOTPMessage)
    //     );
    //     msg.addData(
    //       getName(MessageEnum.AuthTokenDataMessage),
    //       this.state.token
    //     );

    //     msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    //     msg.addData(
    //       getName(MessageEnum.AuthTokenPhoneNumberMessage),
    //       this.state.phoneValue
    //     );

    //     msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

    //     this.send(msg);
    //   }
    //   //error handling
    //   else if (responseJson && responseJson.errors) {
    //     this.parseApiErrorResponse(responseJson);
    //   } else {
    //     var errorReponse = message.getData(
    //       getName(MessageEnum.RestAPIResponceErrorMessage)
    //     );

    //     this.parseApiCatchErrorResponse(errorReponse);
    //   }
    // } else if (
    //   getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    //   this.requestGoToConfirmationCallId !== null &&
    //   this.requestGoToConfirmationCallId ===
    //     message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    // ) {
    //   // console.log("entered 3");
    //   var responseJson = message.getData(
    //     getName(MessageEnum.RestAPIResponceSuccessMessage)
    //   );
    //   if (responseJson !== undefined && responseJson.data) {
    //     this.setState({
    //       accountStatus: "Confirmation"
    //     });
    //   } else if (responseJson !== undefined && responseJson.errors) {
    //     this.parseApiErrorResponse(responseJson);
    //   } else {
    //     var errorReponse = message.getData(
    //       getName(MessageEnum.RestAPIResponceErrorMessage)
    //     );

    //     this.parseApiCatchErrorResponse(errorReponse);
    //   }
    // } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
    //   var selectedCode = message.getData(
    //     getName(MessageEnum.CountyCodeDataMessage)
    //   );

    //   if (selectedCode !== undefined) {
    //     this.setState({
    //       countryCodeSelected:
    //         selectedCode.indexOf("+") > 0
    //           ? selectedCode.split("+")[1]
    //           : selectedCode
    //     });
    //   }
    // }
    // Customizable Area End
  }
  
  // Customizable Area Start
  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail"
    });
  }

   // validation
   textInput = (e: any) => {

    console.log(e, "focus")
    const name = e.target.name
    const text = e.target.value


    if (e.target.name === "email") {
      if (e.target.value === "") {
        // this.emailError=true
        // this.emailErrorStatus= false
        this.setState({
          emailError: true
        })
        this.setState({
          emailErrorStatus: false
        })
      }

      if (e.target.value.length > 0) {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(e.target.value)) {
          // this.emailError=false
          // this.emailErrorStatus= true

          this.setState({
            emailError: false
          })
          this.setState({
            emailErrorStatus: true
          })


        }
      }
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (pattern.test(e.target.value) && e.target.value !== "") {
        this.setState({
          emailError: false,
          emailErrorStatus: false
        })

      }
      this.setState({
        email: text
      })
    }

    if (e.target.name === "password") {
      var patternPassword = new RegExp(/^(?=.[A-Z])(?=.[#!@$&?<>',\[\]}{=\-)(^%`~+.:;_])(?=.[0-9])(?=.*[a-z]).{8,}$/);
      if (e.target.value === '') {
        this.setState({
          passwordError: true,
          passwordStatusError: false
        })
      }

      // if (e.target.value.length > 6) {
      //   this.setState({
      //     passwordStatusError: false
      //   })
       
      // }
      // else{
      //   this.setState({
      //     passwordStatusError: true
      //   })
      // }
      this.setState({
        password: e.target.value
      })
    }

    if (e.target.name === "conformPassword") {
      if (e.target.value === '') {
        this.setState({
          reTypePasswordError: true,
          reTypePasswordStatusError: false
        })
      }

      if (e.target.value !== '' && e.target.value != this.state.password) {
        this.setState({
          reTypePasswordError: false,
          reTypePasswordStatusError: true
        })
      }
      if (e.target.value != '' && e.target.value === this.state.password) {

        this.setState({
          reTypePasswordError: false,
          reTypePasswordStatusError: false
        })
      }
      this.setState({
        reTypePassword: e.target.value
      })
    }
  }

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }, e?: any) {
    //change status to OTP
    e.preventDefault()

    if (this.state.email === '') {
      this.setState({
        emailError: true,
        emailErrorStatus: false
      })
    }
    if (this.state.email.length > 0) {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(this.state.email)) {
        // this.emailError=false
        // this.emailErrorStatus= true

        this.setState({
          emailError: false
        })
        this.setState({
          emailErrorStatus: true
        })


      }
    }


    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (this.state.email !== '' && pattern.test(this.state.email)) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : ""
    });

    const data = {
      // type: values.accountType ? values.accountType : "email_account",
      // attributes: {
      //   email: values.email ? values.email : ""
      // }

      email:this.state.email
    };

    const httpBody = data

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    console.log(requestMessage, 'requestMessage...')

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    // console.log("entered phone validation code");
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    console.log(this.state.countryCodeSelected);
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : ""
    });

    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: this.state.phoneValue
      }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : ""
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  goToConfirmationAfterPasswordChange(values:any) {
    console.log(this.state.password, this.state.reTypePassword, "p and r")
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // const data = {
    //   token: this.otpToken ? this.otpToken : "",
    //   new_password: values.password
    // };

    const data = {
      new_password:this.state.password,
    }

    const httpBody = {
      data: data
    };
console.log(httpBody, "httpBody")
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethodNewPassword
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    console.log(requestMessage, "rqust.....")

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  goToConfirmationAfterPasswordChangeWeb() {
    console.log(this.state.password, this.state.reTypePassword, "p and r")
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // const data = {
    //   token: this.otpToken ? this.otpToken : "",
    //   new_password: values.password
    // };

    const data = {
      new_password:this.state.password,
    }

    const httpBody = {
      data: data
    };
console.log(httpBody, "httpBody")
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethodNewPassword
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    console.log(requestMessage, "rqust.....")

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
 
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
