import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  emailError: boolean;
  passwordError: boolean;
  emailErrorStatus: boolean;
  showPassword: boolean;
  open: boolean;
  loader: any;
  messageError: any;
  token:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiEmailLoginCallId: any;
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  emailError = false;
  showPassword = false;
  emailErrorStatus = false;
  passwordError = false;
  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);
  
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]
    
    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      emailError: false,
      showPassword: false,
      passwordError: false,
      emailErrorStatus: false,
      open: false,
      loader: false,
      messageError: "",
      token:''
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    if (localStorage.getItem("token")) {
        window.location.assign("/home/dashboard");
    }
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin()
  };

  btnEmailLogInProps = {
    color: "#6200EE",
    onPress: () => this.doEmailLogIn()
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword()
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    }
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    }
  };

  // email handle change
  txtInputEmail(e: any) {

    const name = e.target.name
    const text = e.target.value
    if (e.target.name === "email") {
      if (e.target.value === "") {

        this.setState({
          emailError: true
        })
        this.setState({
          emailErrorStatus: false
        })
      }

      if (e.target.value.length > 0) {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+))|("[\w-\s]+")([\w-]+(?:\.[\w-]+)))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(e.target.value)) {

          this.setState({
            emailError: false
          })
          this.setState({
            emailErrorStatus: true
          })


        }
      }
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+))|("[\w-\s]+")([\w-]+(?:\.[\w-]+)))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (pattern.test(e.target.value) && e.target.value !== "") {
        this.setState({
          emailError: false,
          emailErrorStatus: false
        })

      }
      this.setState({
        email: text
      })
    }
    if (e.target.name === "password") {
      if (e.target.value === "") {
        this.setState({
          passwordError: true
        })
      }
      else {
        this.setState({
          passwordError: false
        })
      }
      this.setState({
        password: text
      })
    }
  }


  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {

    // Customizable Area Start
    console.log(this.props, "props....")
    console.log(message, "userData....")

    this.setState({
      open: false,
      loader: false
    })

    console.log(message.properties?.RestAPIResponceSuccessMessage?.meta?.token, "success")

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id
            && this.apiEmailLoginCallId !== null
            && this.apiEmailLoginCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

            this.apiEmailLoginCallId = null;

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({token: responseJson?.meta?.token});

            console.log('@@@@@@@@@@@@@@@@@@@@', responseJson);

            if (message.properties?.RestAPIResponceSuccessMessage?.meta != null) {

              console.log(message.properties?.RestAPIResponceSuccessMessage?.data, "userInfo")
              localStorage.setItem("userInfo", JSON.stringify(message.properties?.RestAPIResponceSuccessMessage?.data?.attributes))
              localStorage.setItem("userId", message.properties?.RestAPIResponceSuccessMessage?.data?.id)
              localStorage.setItem("token", message.properties?.RestAPIResponceSuccessMessage?.meta?.token)
              let userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
              let role = userInfo?.role?.data?.attributes?.name;
      
            
              window.location.assign("/home/dashboard");
      
            }


            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
    // Customizable Area End
  }
  
  // Customizable Area Start
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
  
  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(e?: any): boolean {
    // e.preventDefault();
    this.setState({
      loader: true
    })
    // this.props.navigation.navigate("SocialMediaAccountRegistrationScreen")

    if (this.state.email === '') {
      // this.emailError = true
      this.setState({
        emailError: true
      })
    }

    if (this.state.email.length > 0) {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+))|("[\w-\s]+")([\w-]+(?:\.[\w-]+)))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(this.state.email)) {
        // this.emailError=false
        // this.emailErrorStatus= true

        this.setState({
          emailError: false
        })
        this.setState({
          emailErrorStatus: true
        })

      }
    }

    if (this.state.password === '') {
      this.setState({
        passwordError: true
      })
    }
    else {

      // if (
      //   this.state.email === null ||
      //   this.state.email.length === 0 ||
      //   !this.emailReg.test(this.state.email)
      // ) {

      //   this.showAlert("Error", configJSON.errorEmailNotValid);
      //   return false;
      // }

      // if (this.state.password === null || this.state.password.length === 0) {
      //   this.showAlert("Error", configJSON.errorPasswordNotValid);
      //   return false;
      // }

      const header = {
        "Content-Type": configJSON.loginApiContentType
      };

      const attrs = {
        email: this.state.email,
        password: this.state.password
      };

      const data = {
        // type: "email_account",
        attributes: attrs
      };

      const httpBody = attrs

      console.log(httpBody, "data......")

      this.setState({
        open: !this.state.open
      })
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiEmailLoginCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginAPiEndPointt
      );
      console.log(requestMessage, "jhdgcfdhf")
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );
      console.log(requestMessage, "api request,,.,...")
      runEngine.sendMessage(requestMessage.id, requestMessage);


    }
    return true;
  }

  callGetValidationApi() {
    
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  // Customizable Area End
}
