// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
// import HomeScreen from "../../components/src/HomeScreen";
import HomeScreen from "../../components/src/HomeScreen.web";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Settings2 from "../../blocks/Settings2/src/Settings2";
import Maps from "../../blocks/Maps/src/Maps";
import AdHocReporting from "../../blocks/AdHocReporting/src/AdHocReporting";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import ProjectTemplates from "../../blocks/ProjectTemplates/src/ProjectTemplates";
import CfPdfMerger3 from "../../blocks/CfPdfMerger3/src/CfPdfMerger3";
import TeamBuilder from "../../blocks/TeamBuilder/src/TeamBuilder";
import JobListing2 from "../../blocks/JobListing2/src/JobListing2.web";
import Search from "../../blocks/Search/src/Search";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import AssessmentTest from "../../blocks/AssessmentTest/src/AssessmentTest";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import WordpressIntegration from "../../blocks/WordpressIntegration/src/WordpressIntegration";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import Download from "../../blocks/Download/src/Download";
import Scheduling from "../../blocks/Scheduling/src/Scheduling";
import Sorting from "../../blocks/sorting/src/Sorting";
import Notifications from "../../blocks/Notifications/src/Notifications";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import IntegrationWithStudentApp3 from "../../blocks/IntegrationWithStudentApp3/src/IntegrationWithStudentApp3";
import PortfolioManagement from "../../blocks/PortfolioManagement/src/PortfolioManagement";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";

const routeMap = {
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Maps:{
 component:Maps,
path:"/Maps"},
AdHocReporting:{
 component:AdHocReporting,
path:"/AdHocReporting"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
ProjectTemplates:{
 component:ProjectTemplates,
path:"/ProjectTemplates"},
CfPdfMerger3:{
 component:CfPdfMerger3,
path:"/CfPdfMerger3"},
TeamBuilder:{
 component:TeamBuilder,
path:"/TeamBuilder"},
JobListing2:{
 component:JobListing2,
path:"/JobListing2"},
Search:{
 component:Search,
path:"/Search"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},

Dashboard:{
 component:Dashboard,
path:"/Dashboard"},

CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/",
exact:true
},

EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration",

},
WordpressIntegration:{
 component:WordpressIntegration,
path:"/WordpressIntegration"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Download:{
 component:Download,
path:"/Download"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
IntegrationWithStudentApp3:{
 component:IntegrationWithStudentApp3,
path:"/IntegrationWithStudentApp3"},
PortfolioManagement:{
 component:PortfolioManagement,
path:"/PortfolioManagement"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: HomeScreen,
    path: '/Home',
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
     <div>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        {/* <HomeScreen /> */}
        <ModalContainer />
        </div>
    );
  }
}

export default App;