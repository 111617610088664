import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import { imgBackground, imgLogo, imgMiddle } from "./assets";
import Divider from '@material-ui/core/Divider';

import JobListing2Controller, {
  Props,
  configJSON
} from "./JobListing2Controller";

const styles: any = (theme: any) => ({
  root: {
    // margin: '30px 0px 30px 50px',
    background: 'rgba(255, 255, 255, 1)',
    padding: 20,
    borderRadius: 20,
    boxShadow: '2px 3px 10px 3px rgb(0 0 0 / 20%)',
  },
  textlabel_newjobRolesText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: 'rgba(23, 23, 37, 1)',
    fontFamily: '"HelveticaNeue-Medium", sans-serif',
    fontSize: 24,
  },
  textlabel_Text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: 'rgba(33, 33, 33, 1)',
    fontFamily: '"HelveticaNeue-Medium", sans-serif',
    fontSize: 20,
    margin: '20px 0px',
    "@media (max-width:1024px)": {
      fontSize: 14,
    },
  },
  textlabel_letsgetstartedInputs: {
    '& .textlabel_letsgetstartedInput': {
      height: 30,
      width: 370,
      "@media (max-width:1024px)": {
				width: 200,
			},
      padding: 6,
      marginBottom: 18,
      borderRadius: 4,
      border: '1px solid rgba(89, 89, 89, 0.6)',
      color: 'rgba(89, 89, 89, 0.6)',
    }
  },
  button_continueButton: {
    backgroundColor: 'rgba(1, 129, 123, 1)',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: 6,
    color: 'rgba(255, 255, 255, 1)',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontFamily: '"HelveticaNeue-Medium", sans-serif',
    fontSize: 18,
    textAlign: 'center',
    boxShadow: 'none',
    width: 144
  },
  lableRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '60%'
  },
  lableRoot2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '50%',
    "@media (max-width:1024px)": {
      width: '100%',
    },
  },
  textlabel_captionText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    fontFamily: '"HelveticaNeue", sans-serif',
    color: 'rgba(89, 89, 89, 0.6)',
  },
  textlabel_jobInput: {
    height: 30,
    width: 370,
    "@media (max-width:1024px)": {
      width: 200,
    },
    padding: 6,
    marginBottom: 18,
    borderRadius: 4,
    border: '1px solid rgba(89, 89, 89, 0.6)',
    color: 'rgba(89, 89, 89, 0.6)',
  },
  textarea_Input: {
    height: 150,
    width: 370,
    "@media (max-width:1024px)": {
      width: 200,
      height:130
    },
    padding: 6,
    marginBottom: 18,
    borderRadius: 4,
    border: '1px solid rgba(89, 89, 89, 0.6)',
    color: 'rgba(89, 89, 89, 0.6)',
  },
  fileupload_input: {
    height: 30,
    width: 370,
    "@media (max-width:1024px)": {
      width: 200,
    },
    padding: 6,
    marginBottom: 18,
    borderRadius: 4,
    border: '1px solid rgba(89, 89, 89, 0.6)',
    color: 'rgba(89, 89, 89, 0.6)',
  },
  span:{
    marginLeft:10
  },
  date_Input:{
    height: 30,
    width: 130,
    "@media (max-width:1024px)": {
      width: 100,
    },
    padding: 6,
    margin:'0 10px',
    borderRadius: 4,
    border: '1px solid rgba(89, 89, 89, 0.6)',
    color: 'rgba(89, 89, 89, 0.6)',
  }

});

class JobListing2 extends JobListing2Controller {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.root} >
          <Typography className={classes.textlabel_newjobRolesText}>New Job Role</Typography>
          <Divider />
          <form>
            <Typography className={classes.textlabel_Text}>Let's Get Started</Typography>
            <div>
              <Grid container>
                <Grid item xs={12} sm={6} className={classes.textlabel_letsgetstartedInputs} >
                  <input
                    type="text"
                    id="departmentName"
                    name='departmentName'
                    placeholder="Department Name"
                    className="textlabel_letsgetstartedInput"
                    onChange={(e) => this.txtInput(e)}
                  />
                  <br />
                  <br />
                  <input
                    type="text"
                    id="location"
                    name='location'
                    placeholder="Location"
                    className="textlabel_letsgetstartedInput"
                    onChange={(e) => this.txtInput(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.textlabel_letsgetstartedInputs}>
                  <input
                    type="text"
                    id="jobtitle"
                    name='jobtitle'
                    placeholder="Job Title"
                    className="textlabel_letsgetstartedInput"
                    onChange={(e) => this.txtInput(e)}
                  />
                  <br />
                  <br />
                  <input
                    type="text"
                    id="minimumEducation"
                    name='minimumEducation'
                    placeholder="Minimum Education"
                    className="textlabel_letsgetstartedInput"
                    onChange={(e) => this.txtInput(e)}
                  />
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container>
                <Grid item xs={12} sm={6} >
                  <Typography className={classes.textlabel_Text} >Specific Joining Date</Typography>
                  <div className={classes.lableRoot} >

                    <label className={classes.lable}>
                      <input
                        type="radio"
                        name="isJoin"
                        value='true'
                        onChange={(e) => this.txtInput(e)}
                      />
                      <span className={classes.span}>Yes</span>
                    </label>

                    <input
                      type="type"
                      name="isJoindate"
                      placeholder="dd/mm/yyyy"
                      className={classes.date_Input}
                      onChange={(e) => this.txtInput(e)}
                    />

                    <label>
                      <input 
                      type="radio" 
                      name="isJoin" 
                      value='false' 
                      onChange={(e) => this.txtInput(e)}
                      />
                      <span className={classes.span}>No</span>
                    </label>
                  </div>

                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.textlabel_Text} >Is there any application deadline?</Typography>
                  <div className={classes.lableRoot} >
                    <label>
                      <input 
                      type="radio" 
                      name="is_application" 
                      value='true' 
                      onChange={(e) => this.txtInput(e)}
                      />
                      <span className={classes.span}>Yes</span>
                    </label>

                    <input 
                    type="type" 
                    name="applicationDeadline" 
                    placeholder="dd/mm/yyyy" 
                    className={classes.date_Input}
                    onChange={(e) => this.txtInput(e)}
                    />

                    <label>
                      <input 
                      type="radio" 
                      name="is_application" 
                      value='false' 
                      onChange={(e) => this.txtInput(e)}
                      />
                      <span className={classes.span}>No</span>
                    </label>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div>
              <Grid>
                <Typography className={classes.textlabel_Text} >Can this job performed remotely? if yes, we will add a "Remote"\tag to your post.</Typography><br />
                <div className={classes.lableRoot2} >
                  <label>
                    <input type="radio" name="remotelyPerformed" value='yes' 
                    onChange={(e) => this.txtInput(e)}
                    />
                    <span className={classes.span}>Yes</span>
                  </label>

                  <label>
                    <input type="radio" name="remotelyPerformed" value='temporarily' 
                    onChange={(e) => this.txtInput(e)}
                    />
                    <span className={classes.span}>Temporarily due to COVID-19</span>
                  </label>

                  <label>
                    <input type="radio" name="remotelyPerformed" value='no' 
                    onChange={(e) => this.txtInput(e)}
                    />
                    <span className={classes.span}>No</span>
                  </label>
                </div>
              </Grid>
            </div>

            <div>
              <Grid container >
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.textlabel_Text} >
                    How many Hires?
                    <br />
                    <span className={classes.textlabel_captionText} >(This helps us match you with the right number of applicants)</span>
                  </Typography>
                  {/* <Typography  ></Typography> */}
                  <input
                    type="text"
                    id="numberOfHires"
                    name="numberOfHires"
                    placeholder="02"
                    className={classes.textlabel_jobInput}
                    onChange={(e) => this.txtInput(e)}
                  />
                  <Typography className={classes.textlabel_Text} >Job Status</Typography>
                  <input
                    type="text"
                    id="status"
                    name="jobStatus"
                    placeholder="Active"
                    className={classes.textlabel_jobInput}
                    onChange={(e) => this.txtInput(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.textlabel_Text}>Job Description</Typography>
                  <textarea
                    name="jobDescription"
                    id="description"
                    className={classes.textarea_Input}
                    onChange={(e) => this.txtInput(e)}
                  >
                    long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
                  </textarea>
                </Grid>
              </Grid>
            </div>

            <div>
              <Grid>
                <Typography className={classes.textlabel_Text} >Send Resume</Typography>
                <input 
                type="file" 
                name="fileupload" 
                id="fileupload"  
                className={classes.fileupload_input} 
                onChange={(e) => this.txtInput(e)}
                />
                {/* <label htmlFor="fileupload"> Select a file to upload</label> */}
              </Grid>
            </div>
            <div style={{textAlign:'end'}}>
              <Button
                variant="contained"
                className={classes.button_continueButton}
                onClick={(e) => this.createAccount(e)}
              >Continue</Button>
            </div>

          </form>

        </div>
      </>
    )
  };
}

export default withStyles(styles)(JobListing2);

